import React, { useState, useEffect } from "react";
import { Container, InputGroup, FormControl, Button } from "react-bootstrap";
import Nav from "./components/Nav";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import Web3 from "web3";
import abi from "./abi.json";
import "./App.css";
import logo from "./images/logo.png";
import busd from "./images/busd.png";

const web3 = new Web3(
  new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
);

export const injected = new InjectedConnector({
  supportedChainIds: [56],
});

export const network = new NetworkConnector({
  urls: { 56: "https://bsc-dataseed.binance.org/" },
  defaultChainId: 1,
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 56: "https://bsc-dataseed.binance.org/" },
  qrcode: true,
  pollingInterval: 12000,
});

const App = () => {
  const [addressInput, setAddressInput] = useState("");
  const [data, setData] = useState({
    balance: 0,
    unclaimedReward: 0,
    distributedReward: 0,
    earned: 0,
  });
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();

  console.log(library, active, account, connector);

  useEffect(() => {
    if (active) {
      fetchData(account);
    }
  }, [active]);

  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }
 
  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  async function fetchData(address) {
    try {
      const contract = new web3.eth.Contract(
        abi,
        "0x118e3038a5ba914EeA47694BdB834D2Ff7C53776"
      );
      const shares = await contract.methods.shares(address).call();
      const totalDistributed = await contract.methods.totalDistributed().call();
      const unpaid = await contract.methods.getUnpaidEarnings(address).call();
      const balance = shares.amount;
      const realised = shares.totalRealised;
      const excluded = shares.totalExcluded;
      console.log(shares, totalDistributed, unpaid);
      setData({
        balance: (balance / 10 ** 9).toFixed(2),
        unclaimedReward: (unpaid / 10 ** 18).toFixed(2),
        distributedReward: (totalDistributed / 10 ** 18).toFixed(2),
        earned: (realised / 10 ** 18).toFixed(2),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function claim() {
    if (active) {
      try {
        const contract = new library.eth.Contract(
          abi,
          "0x118e3038a5ba914EeA47694BdB834D2Ff7C53776"
        );
        await contract.methods.claimDividend().send({
          from: account,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Connecting your wallet...");
      connect();
    }
  }

  return (
    <>
      <Nav connect={connect} disconnect={disconnect} active={active} />
      <Container>
        <Container className="dark-bg p-1 pt-3 my-3 shadow text-center">
          <p>(Please make sure you are visiting https://app.busybeecoin.com)</p>
        </Container>

        <Container className="dark-bg p-1 pt-3 px-5 my-3 shadow">
          <InputGroup className="mb-3">
            <FormControl
              onChange={(e) => setAddressInput(e.target.value)}
              value={addressInput}
              placeholder="Enter address"
              aria-label="Enter address"
              aria-describedby="basic-addon2"
            />
            <Button
              onClick={() => fetchData(addressInput)}
              variant="btn btn-dark"
              id="button-addon2"
            >
              Submit
            </Button>
          </InputGroup>
        </Container>
        <div className="container dark-bg p-3 my-3 shadow">
          <div className="row">
            <div className="col-sm-6">
              <div className="text-center">
                <p className="h2">Your Wallet</p>
                <p className="h3">
                  <span className="font-weight-bold">{data.balance}</span>
                  <img
                    src={logo}
                    alt="logo"
                    className="ml-3"
                    height="60"
                    width="60"
                  />
                </p>
                <p className="h3 font-weight-bold">
                  0 <img src={busd} className="ml-3" height="40" width="40" />
                </p>
                <hr />
                <p className="h2">Total Earned</p>
                <p className="h3">
                  <span id="earned" className="font-weight-bold">
                    {data.earned}
                    <img src={busd} className="ml-3" height="40" width="40" />
                  </span>
                </p>
              </div>
            </div>
            <div className="col-sm-6 text-center">
              <p className="h2">Reward Not Claimed</p>
              <p className="h3">
                <span id="unclaimed" className="font-weight-bold">
                  {data.unclaimedReward}
                  <img src={busd} className="ml-3" height="40" width="40" />
                </span>
              </p>
              <button className="btn btn-primary" onClick={claim}>
                CLAIM MANUALLY
              </button>
              <div className="my-3">
                <small>
                  Rewards are automatically sent every 60 minutes. It can,
                  however, take longer depending on your holdings and trading
                  volume. Rewards will be triggered once they are big enough to
                  cover the gas fees. If you are a smaller holder it may take
                  from a couple hours to a few days for rewards to appear in
                  your wallet. You can also manually claim unclaimed rewards,
                  but you will need to pay the gas fees.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="container dark-bg p-3 my-3 shadow">
          <div className="row">
            <div className="col-sm-6 text-center">
              <p className="h2">Reward Distributed To Holders</p>
              <p className="h3">
                <span id="distributed" className="font-weight-bold">
                  {data.distributedReward}
                </span>
                <img src={busd} className="ml-3" height="40" width="40" />
              </p>
            </div>
            <div className="col-sm-6">
              <div>
                <p>BU$YBEE Contract</p>
                <p>0x703dF95Dfd326D2C0C69205487E4e30D04a0Bf5d</p>
                <p>BUSD Contract</p>
                <p>0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default App;
